.h-100{height: 100%;}
.paddingContainer{padding: 0 0 0 8px;}
.items-selected{height: calc(100% - 162px);}
@media (max-width: 1400px) {
    .h-100, .items-selected{height: auto !important;}
    .containerFactura{overflow: auto;}
    .paddingContainer{padding: 8px 0 0 0;}
}

.css-irrfx6 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    margin-bottom: -17px;
}
