
/* Modal */
.ReactModalPortal > div{
  opacity: 0;
}

.ReactModalPortal .ReactModal__Overlay {
  align-items: center;
  display: flex;
  justify-content: center;
  transition: opacity .2s ease-in-out;
  z-index: 999;
}

.modal-fondo {
  background-color: rgba(0, 0, 0, 0.3);
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

.modal {
  background: white;
  border-radius: 5px;
  color: rgb(51, 51, 51);
  display: inline;
  max-height: 620px;
  max-width: 500px;
  outline: none;
  padding: 10px;
}

/* FABS */
.fab {
  border-radius: 100%;
  bottom: 25px;
  font-size: 30px;
  padding: 25px;
  position: fixed;
  right: 25px;
}

.fab-danger {
  border-radius: 100%;
  bottom: 25px;
  font-size: 22px;
  position: fixed;
  left: 25px;
  width: 70px;
  height: 70px;
}