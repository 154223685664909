.maq{border: 1px red solid;}
.flex{
  display: flex;
}
.itemsList{
  width: calc(100% - 340px);
  height: 100%;
  display: flex;
  align-self: start;
  padding: 10px;
}
.itemsSelected{
  width: 340px;
  height: 100%;
  display: flex;
  align-self: start;
  padding: 10px;
}
.justify-center{
  justify-content: center;
}
.mainBody{
  margin-top: 70px;
  height: calc(100vh - 70px);
  overflow-x: hidden;
}
.card{
  border-radius: 20px;
  box-shadow: 0px 0px 5px 0px rgb(0 0 0 / .7);
  width: 100%;
  height: 100%;
  padding: 10px;
}

.content2and3 {
  display: flex;
  justify-content: space-between;
}

@media only screen and (min-width: 600px) {
  .content2 {
    display: flex;
  }
}

@media only screen and (max-width: 600px) {
  .content2 {
    display: flex;
  }
}