.buttonSell {
    background-color: #1BD935;
    border: none;
    border-radius: 3px;
}

.buttonSell:hover { 
    background-color: #199e2b;
    cursor: pointer;
}

@media only screen and (min-width: 600px) {
    .buttonSell {
        width: 150px;
    }
}
